/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&display=swap'); */

@import "@fontsource/montserrat";
@import "@fontsource/rubik";

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

h1,
h2,
h3 {
    font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
}

h1 {
    font-weight: 700 !important;
    font-size: 46px !important;
    color: #01b5af;
}

/* AHI branding */
.navbar-text,
.nav-link {
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    font-weight: 600;
    color: #426da9 !important;
}

.appTitle {
    margin-left: 1rem;
    color: #ccc;
}

.logo_header {
    height: 70px;
}

nav {
    background-color: #fff;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
    -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
}

.footer .top_divider {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDZweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik02NDAgMTQwTDEyODAgMEgweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik02NDAgOThsNjQwLTk4SDB6Ii8+PC9nPjwvc3ZnPg==);
    background-size: 100% 106px;
    top: 0;
    height: 106px;
    z-index: 1;
}

.footer .bottom_divider {
    display: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxODNweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiM0MjZkYTkiPjxwYXRoIGQ9Ik0xMjgwIDBMNjQwIDcwIDAgMHYxNDBsNjQwLTcwIDY0MCA3MFYweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDBIMGw2NDAgNzAgNjQwLTcweiIvPjwvZz48L3N2Zz4=);
    background-size: 100% 183px;
    padding-right: 80px !important;
    height: 183px;
    z-index: 1;
    transform: rotateX(180deg);
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    /* background-color: #00aeae; */
}

.footer {
    background-color: #00aeae !important;
    max-width: 100% !important;
    font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    color: #fff;
    line-height: 1.7em;
    padding-bottom: 90px;
    flex-grow: 1;
}

.footer_body {
    max-width: 80%;
    width: 80%;
    margin: 2rem auto 0 !important;
    z-index: 2;
}

.footer_body div {
    margin-top: 1rem;
}

.footer_about__logo {
    width: 200px;
    height: 100px;
    background-color: #fff;
}

.footer_contact {
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.footer_links {
    font-weight: 400;
}

.footer_links ul {
    padding-left: 0;
}

.footer h3 {
    font-family: "Rubik", Helvetica, Arial, Lucida, sans-serif;
    font-size: 24px;
    color: #fff;
}

.footer ul {
    list-style-type: none;
}

.footer li {
    color: #fff;
}

.footer a {
    color: #fff;
    text-decoration: none;
}

#main {
    padding-top: 120px;
    margin-bottom: 3rem;
    flex-grow: 1;
}

.AllocationCard {
    margin-top: 2em;
}

.allocationTable {
    width: 90% !important;
    margin: 1rem auto;
}

.btn.disabled {
    opacity: 0.3 !important;
    margin: 1rem 0;
}

.btn {
    margin: 1rem 0;
}

button.close {
    position: absolute;
    right: 0;
    margin-right: 1rem;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 700;
}

.alert-danger button.close {
    color: #842029;
}

* button.close:hover {
    color: #333;
}

.navbar-brand {
    margin-left: 1rem;
}

.login,
.randomiseForm {
    margin: 0 auto;
}

.login label {
    width: auto;
}

.login .btn,
.randomiseForm .btn {
    margin: 2rem auto 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login input {
    width: 100%;
}

/* .login{
    width: 40%;
} */

input[type="text"] {
    margin-bottom: 0.6rem;
}

@media screen and (min-width: 1000px) {
    .allocationTable {
        width: 60% !important;
    }
}

@media screen and (min-width: 760px) {
    .login {
        width: 40%;
    }

    .randomiseForm {
        width: 40%;
    }
}

@media screen and (max-width: 800px) {
    .appTitle {
        display: none;
    }
}

@media screen and (min-width: 572px) {
    label {
        padding-top: 0 !important;
    }

    .footer_about {
        padding-right: 5rem !important;
    }

    /* .container, .container-fluid{
        width: 75% !important;
    } */
}
